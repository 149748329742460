import React, {useState } from 'react';
// import { Link, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getLayout } from "./../../../store/settings/selectors";
import { getTranslations, getLocale } from "./../../../store/localization/selectors";
// import { getUser } from "./../../../store/auth/selectors";

import {LAYOUT_XS, LAYOUT_SM, LAYOUT_MD} from './../../../settings';

import NewsService from './../../../services/NewsService';

import Logo from './../../../components/App/Logo';
import Container from './../../../components/App/Container';
import Spacer from './../../../components/App/Spacer';
import Post from './../../../components/App/Post';

// import FindUs from './../../../components/Common/FindUs';
import Sidebar from './Sidebar';


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;

  height: calc(100vh - 50px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  margin-top: 3px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* min-height: 100%; */
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
`;
const MacroRow = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  margin-left: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
`;
const MacroCol = styled.div`
  padding: 0px ${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;
`;

const Notification = styled.div`
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  color: #A3A3A3;
  margin-top:20px;
`;


export default function SocialWall(){

  const layout = useSelector(getLayout);
  const translations = useSelector(getTranslations);
  const lang = useSelector(getLocale);
  // const config = useSelector(getConfig);
  // const user = useSelector(getUser);

  const [list, setList] = useState(null);
  const [listDx, setListDx] = useState(null);

  if(!list){
    NewsService.getList("Social Wall").then(ret  => {
      setList(ret);
    }).catch(err => console.log('There was an error:' + err));
  }
  if(!listDx){
    NewsService.getList("Frame DX").then(ret  => {
      setListDx(ret);
      // console.log(ret);
    }).catch(err => console.log('There was an error:' + err));
  }



  return (
    <Wrapper>
      { layout !== LAYOUT_XS && layout !== LAYOUT_SM ?
        <Spacer size={{value: 34, unit: "px"}} />
      : <Sidebar selected={"socialwall"} /> }
      <MainContainer>
        <Container>
          <MacroRow layout={layout}>
            <MacroCol size="66" layout={layout}>
              <center>
                { layout === LAYOUT_XS || layout === LAYOUT_SM ?
                  <Spacer size={{value: 34, unit: "px"}} />
                : null }
                <Logo height={{value: 56, unit: "px"}} variant="plus" />
                <Spacer size={{value: 27, unit: "px"}} />
              </center>
              {list ? list.map(item =>
                <React.Fragment key={item.titolo_it}>
                  <Post
                    tags={["Wine People"]}
                    title={item["titolo_"+(lang === "it" ? lang : "en")]}
                    abstract={ReactHtmlParser(item["abstract_"+(lang === "it" ? lang : "en")])}
                    content={item["testo_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(item["testo_"+(lang === "it" ? lang : "en")]) : null}
                    ctaText={item["cta_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(item["cta_"+(lang === "it" ? lang : "en")]) : null}
                    ctaLink={item["link_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(item["link_"+(lang === "it" ? lang : "en")]) : null}
                    timestamp={item.data_pubblicazione}
                    image={item.immagine}
                  />
                  <Spacer size={{value: 34, unit: "px"}} />
                </React.Fragment>
              ) : null }

            </MacroCol>
            <MacroCol size="34" layout={layout}>
              <Spacer size={{value: 95, unit: "px"}} />
              {/*<FindUs />
              <Spacer size={{value: 34, unit: "px"}} />*/}
              {listDx ? listDx.map((item, i) =>
                <React.Fragment key={i}>
                  <Post
                    isHighlighted={true}
                    title={item["titolo_"+(lang === "it" ? lang : "en")]}
                    abstract={ReactHtmlParser(item["abstract_"+(lang === "it" ? lang : "en")])}
                    content={item["testo_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(item["testo_"+(lang === "it" ? lang : "en")]) : null}
                    ctaText={item["cta_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(item["cta_"+(lang === "it" ? lang : "en")]) : null}
                    ctaLink={item["link_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(item["link_"+(lang === "it" ? lang : "en")]) : null}
                    image={item.immagine}
                  />
                  <Spacer size={{value: 34, unit: "px"}} />
                </React.Fragment>
              ) : null }
            </MacroCol>
          </MacroRow>
          <Notification>
            {translations["UI"]["_homepage-language-info"]}
            <Spacer size={{value: 34, unit: "px"}} />
          </Notification>
        </Container>
      </MainContainer>
    </Wrapper>

  );

}
