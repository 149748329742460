import React from 'react';
import Spacer from "../../../components/App/Spacer";
import HomeProduct from "./HomeProduct";
import {useSelector} from "react-redux";
import {getTranslations} from "../../../store/localization/selectors";
import styled from "styled-components";
import {LAYOUT_MD, LAYOUT_SM, LAYOUT_XS} from "../../../settings";
import {getLayout} from "../../../store/settings/selectors";

const Title = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #7B3572;
`;

const MacroRow = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  margin-left: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
`;

const MacroCol = styled.div`
  padding: 0px ${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;

  ${props => props.autoHeight ? `

    & > * {
      height: 100%;
      display: flex;
    }
  ` : `

  `};
`;

function FeaturedList({ block, products }) {

  const translations = useSelector(getTranslations);
  const layout = useSelector(getLayout);

  return (
    <div>
      <Title>{translations["UI"][`_recommended-${block.logic}`]}</Title>
      <Spacer size={{value: 14, unit: "px"}} />
      <MacroRow layout={layout}>
        {block.items.map((item, i) => products.hasOwnProperty(item.item) ?
          <MacroCol key={i} size="33" layout={layout} autoHeight={true}>
            <HomeProduct item={products[item.item]} id={item.item} />
          </MacroCol> : null
        )}
      </MacroRow>
    </div>
  );

}

export default FeaturedList;
