import React, {useRef, useState, useEffect} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from "react-redux";
import {getRoles, getToken} from "../../store/auth/selectors";
import {useTheme} from "../../themes";
import {getChatEnvByDomain, getAccountSettings} from "../../store/settings/selectors";
import {getLocale, getTranslations} from "../../store/localization/selectors";
import { getLayout } from "./../../store/settings/selectors";

import {LAYOUT_XS, LAYOUT_SM, LAYOUT_MD} from './../../settings';

import Container from './../App/Container';
import Spacer from './../App/Spacer';
import Button from "../App/Button";
import {logout} from "../../store/auth/actions";
import {getProfile} from "../../store/chat/actions";
import Analytics from "../Analytics/Analytics";

import CompactMenuIcon from './../../components/App/CompactMenuIcon';

export const CompactMenuIconWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
   width: 46px;
   height: 46px;
   flex-grow: 0;
   flex-shrink: 0;
   margin-left: 7px;
   z-index: 9999;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  overflow-x: none;
  overflow-y: auto;
`;

const MainContainer = styled.div`
  margin-top: 3px;
  padding-bottom: 30px;
  width: 100%;
`;
const MacroRow = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  margin-left: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
`;
const MacroCol = styled.div`
  padding: 0px ${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;
`;

const TabButton = styled.button`
  display: inline-flex;
  align-items: center;
  border: none;
  border-radius: 15px;
  font-size: 12px;
  padding: 10px;
  background-color: transparent;
  color: ${props => (!props.active ? props.color : props.activeColor)};
  background: ${props => (!props.active ? (props.background) : props.activeBackground)};
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 10px;
`;

const Buttons = styled.div`
  padding: 0 12px;
`;

const AlertBox = styled.div`
  text-align: center;
`;

export default function MyArea({ forced = false }) {

  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const translations = useSelector(getTranslations);
  const theme = useTheme();
  const language = useSelector(getLocale);
  const roles = useSelector(getRoles);

  const layout = useSelector(getLayout);
  const accountSettings = useSelector(getAccountSettings);
  const [area, setArea] = useState('profile');
  const accountRef = useRef(null);
  const pluginEnv = useSelector(getChatEnvByDomain);

  const { button } = theme.list.resultsTab;
  const buttonStyles = {
    background: button.normal.background,
    color: button.normal.color,
    activeBackground: button.active.background,
    activeColor: button.active.color,
  };

  const profileLabel = translations['UI']['MyAreaProfile'] ? translations['UI']['MyAreaProfile'] : 'Profilo';
  const premiumLabel = translations['UI']['MyAreaAccountPremium'] ? translations['UI']['MyAreaAccountPremium'] : 'Account premium';
  const premiumCta = translations['UI']['MyAreaCTAPremium'] ? translations['UI']['MyAreaCTAPremium'] : "Passa all’account premium\n";

  const isPremium = roles ? roles.indexOf('userPremium') !== -1 : false;

  useEffect(() => {
    if (accountRef && accountRef.current) {
      accountRef.current.addEventListener('submit::success', (response) => {
        dispatch(getProfile());
      });
      accountRef.current.addEventListener('submit::error', (response) => {
        dispatch(getProfile());
      });
      accountRef.current.addEventListener('unsubscribe::success', () => {
        dispatch(logout());
      });
    } else {
      window.location = '/' + language;
    }
  }, [dispatch, language]);

  return (
    <Wrapper>
      {layout === LAYOUT_XS || layout === LAYOUT_SM ? 
        <CompactMenuIconWrapper>
          <CompactMenuIcon />
        </CompactMenuIconWrapper>
      : null }
      <Spacer size={{value: 34, unit: "px"}} />
      <MainContainer>
        <Container>

          <MacroRow layout={layout}>
            <MacroCol size="10" />
            <MacroCol size="80" layout={layout}>

              {accountSettings.proAccount ? (
                <Buttons>
                  <TabButton active={area === 'profile'} onClick={() => setArea('profile')} {...buttonStyles}>{profileLabel}</TabButton>
                  <TabButton highlithg={!isPremium} active={area === 'premium'} onClick={() => setArea('premium')} {...buttonStyles}>{premiumLabel}</TabButton>
                </Buttons>
              ) : null}

              {token ? (
                <vrf-account env={pluginEnv} locale={language} key={`vrf-account-${area}`} ref={accountRef} route={`${area}`} token={token.accessToken} theme={accountSettings.theme} />
              ) : null}

              {accountSettings.proAccount && !isPremium && area !== 'premium' ? (
                <AlertBox>
                  <Button onClick={() => setArea('premium')} display="inline-block">
                    {premiumCta}
                  </Button>
                </AlertBox>
              ) : null}

              </MacroCol>
            </MacroRow>

          </Container>
        </MainContainer>
        <Analytics key={`analytics-${area}`} name={`user#${area}`} />
      </Wrapper>
  );

}
