import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {connect, useDispatch} from 'react-redux';
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import {compose} from "redux";
import { withTheme } from './../../../themes';

// import { useParams } from 'react-router-dom';
import { useTheme } from './../../../themes';
import { getTranslations, getLocale } from "./../../../store/localization/selectors";
import {getFavorites} from './../../../store/auth/selectors';
import {
  // getArea,
  getConfig,
  // getIfMobile,
  getLayout,
  // getReady,
  // getStatus,
  // getSubarea
} from './../../../store/settings/selectors';
import {getUser, hasPermissionTo} from "./../../../store/auth/selectors";

// import { DOMAINS } from "./../../../domains";
import {LAYOUT_XS, LAYOUT_SM, LAYOUT_MD} from './../../../settings';

import ContactForm from "./../../../components/Common/ContactForm";

// import SheetTopbar from './../../../components/Common/SheetTopbar';
// import Anchors from "./../../../components/Common/Anchors"
// import LabelledValue from "./../../../components/Common/LabelledValue"
import Container from './../../../components/App/Container';
import Spacer from './../../../components/App/Spacer';
import Chunk from './../../../components/App/Chunk';
// import Cover from './../../../components/App/Cover';
import InfoRow from './../../../components/App/InfoRow';
import Image from './../../../components/App/Image';
import Button from './../../../components/App/Button';
import Modal from './../../../components/App/Modal/Modal';
import Icon from './../../../components/App/Icon';
// import Logo from "./../../../components/App/Logo";
import ReadMore from "./../../../components/App/ReadMore";
import Actionbar from "./../../../components/Common/Actionbar";
import FindUs from "./../../../components/Common/FindUs";
import Meta from "./../../../components/App/Meta";

import Result from './../../../components/List/Sidebar/ResultsList/Result';
// import CompanyThumb from './../Companies/CompanyThumb';


// import { requestFile } from './../../../data/network/fileDownloader'
import {setFavorite} from "../../../store/auth/actions";
// import {PERMISSION_CHAT, PERMISSION_PRODUCTPDF} from "../../../store/auth/permissions";


import { showPlansModal} from "../../../store/settings/actions";
import {getCompanyActive} from "../../../store/chat/actions";
import {getActiveCompanies} from "../../../store/chat/selectors";

import {slugify} from "./../../../helpers";

const Fixed = styled.div`
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 999;
  background: #F2F4F4;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
`;
// const CoverContainer = styled.div`
//   position: relative;
//   overflow: hidden;
//   margin-bottom: -110px;
//   width: 100%;
// `;
const MainContainer = styled.div`
  margin-top: 3px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
`;
const MacroRow = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  margin-left: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;

`;
const MacroCol = styled.div`
  padding: 0px ${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;
`;
// const Row = styled.div`
//   display: flex;
//   flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
//   flex-wrap: wrap;
//   margin-right: -7px;
//   margin-left: -7px;

// `;
// const Col = styled.div`
//   padding: 0px 7px;
//   width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;
// `;
// const Company = styled.div`
//   color: #585858;
//   font-size: 32px;
//   font-weight: 500;
// `;
const Position = styled.div`
  display: inline-block;
  background: #7B3572;
  color: #fff;
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  font-size: 24px;
`;
// const Brand = styled.div`
//   color: #585858;
//   font-size: 23px;
//   font-weight: 700;
// `;
const Title = styled.h1`
  font-weight: bold;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
// const Designation = styled.div`
//   color: #585858;
//   font-size: 18px;
//   font-weight: 300;
// `;


const CompanyName = styled.div`
  color: #7B3572;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;
`;

//
// const Text = styled.div`
//   column-count: ${props => props.cols && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.cols : 1};
//
//   & a:hover{
//     font-weight: bold;
//   }
// `;


const PackagingHeader = styled.div`
  display: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "flex" : "none"};
  flex-direction: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "row" : "column"};
  margin-bottom: 10px;

  & > div:nth-child(1){
    width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 35 : 100}%;
  }
  & > div:nth-child(2){
    width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 100}%;
  }
  & > div:nth-child(3){
    width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 100}%;
  }
  & > div:nth-child(4){
    width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 35 : 100}%;
  }
`;
const PackagingHeaderCell = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  color: #000000;
  font-size: 11px;
  font-weight: 600;
  text-align: ${props => props.last && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? "right" : "left"};
  text-transform: uppercase;
`;
const PackagingValue = styled.div`
  display: flex;
  flex-direction: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "row" : "column"};
  border-bottom: 1px solid #7B3572;

  & > div:nth-child(1){
    width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 35 : 100}%;
  }
  & > div:nth-child(2){
    width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 100}%;
  }
  & > div:nth-child(3){
    width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 100}%;
  }
  & > div:nth-child(4){
    width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 35 : 100}%;
  }
`;
const PackagingValueCell = styled.div`
  width: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  padding: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 10 }px 0px;
  text-align: ${props => props.last && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? "right" : "left"};
  color: #606060;
  font-weight: ${props => props.first ? "bold" : "normal"};
  font-size: 13px;
`;
// const Center = styled.div`
//   text-align: center;
// `;


const ContactsModal = styled.div`
  width: 100%;
  max-width: 500px;
  background: #fff;
  padding: 30px;
`;

const ContactsModalHeader = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const ModalTitle = styled.div`
  color: #7B3572;
  font-size: 22px;
  font-weight: 700;
  text-align: left;
`;
const HallImageCnt = styled.div`
  position: relative;
`;
const HallImage = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;
// const ListHighlight = styled.div`
//   text-trasform: uppercase;
//   color: #7B3572;
//   font-size: 15px;
//   font-weight: 700;
// `;

// const HallTitle = styled.div`
//   color: #7B3572;
//   font-size: 12px;
//   font-weight: 700;
// `;
// const HallText = styled.div`
//   color: #585858;
//   font-size: 12px;
//   margin-bottom: 10px;

// `;

const mapStateToProps = (state) => ({
  translations: getTranslations(state),
  config: getConfig(state),
  layout: getLayout(state),
  user: getUser(state),
  favorites: getFavorites(state)
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});



function VirCommonSheet(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
//   const history = useHistory();
  const canProductPdf = useSelector(hasPermissionTo('productpdf:r'));
  const translations = useSelector(getTranslations);
  const lang = useSelector(getLocale);
  // const config = useSelector(getConfig);
  const layout = useSelector(getLayout);
//   const user = useSelector(getUser);
  const { data, config, favorites } = props;
//   const permissions = useSelector(getPermissions);

  const exhibitorId = data && data.hasOwnProperty('scope') ? data.scope.exhibitorId : null
//   const exhibitorName = data && data.hasOwnProperty('options') ? data.options.exhibitor.name : null;

  const activeCompanies = useSelector(getActiveCompanies);
//   let isChatActive = getProductActiveChat(activeCompanies, data);

  useEffect(() => {
    if(exhibitorId){
      if(!activeCompanies.hasOwnProperty(exhibitorId)) {
        dispatch(getCompanyActive(exhibitorId));
      }
    }
  }, [activeCompanies, dispatch, exhibitorId])

  const [showModal, toggleModal] = useState(null);

  function memoHandler() {
  }

  function likeHandler(favorites) {

    dispatch(setFavorite({
      area: 'product',
      idElement: data.id,
      isFavorite: favorites && favorites.filter(item => item.itemType === "product" && item.itemId === data.id).length
    }));

  }

  function pdfSheetHandler() {

    if (canProductPdf || !config.enable_login) {
      toggleModal("technical");
    } else {
      dispatch(showPlansModal())
    }

  }

  // console.log(data.type);
  return data ? (
      <>
          <Meta
              title={data ? data.options.name : null}
              description={data ? (
                data.options.name
              ) : null}
              image={data && data.options.imageId ? data.options.imageId : null}
          />
          <>
              <Fixed>
                  <Container>
                      <Actionbar list={[
                          {
                              name: translations["UI"]["Memo"],
                              icon: theme.icons.set.memo,
                              onClick: memoHandler,
                              show: false,
                              isActive: false,
                              isDisabled: false
                          },{
                              name: favorites && favorites.filter(item => item.itemType === "product" && item.itemId === data.id).length > 0 ? translations["UI"]["Liked"] : translations["UI"]["Like"],
                              icon: theme.icons.set.favorite,
                              onClick: () => likeHandler(favorites),
                              show: true,
                              isActive: favorites && favorites.filter(item => item.itemType === "product" && item.itemId === data.id).length > 0,
                              isDisabled: false
                          },{
                              name: translations["UI"]["Contact"],
                              icon: theme.icons.set.contact,
                              onClick: () => toggleModal("contact"),
                              show: data.companyData && data.companyData.emails && data.companyData.emails.length,
                              isActive: false,
                              isDisabled: false
                          },{
                              name: translations["UI"]["Save"],
                              icon: theme.icons.set.calendar,
                              onClick: null,
                              show: false,
                              isActive: false,
                              isDisabled: false
                          }
                      ]}/>
                  </Container>
              </Fixed>
              <Wrapper>

                  <MainContainer>
                      <Container>

                          <Title>
                              <Icon color={"#606060"} name={theme.icons.set.homepage} size={{value: 35, unit: "px"}} />
                              {data.options.name}
                          </Title>
                          <Spacer size={{value: 12, unit: "px"}} />

                          <MacroRow layout={layout}>
                              <MacroCol size="66" layout={layout}>
                                  <Chunk title={translations["sheet-product"]["Presentation"]}>
                                      { data.options.imageId ?
                                          <>
                                              <Image fit={"contain"} width={{value: 100, unit: "%"}} height={{value: 220, unit: "px"}} src={data.options.imageId.src} alt={data.options.name} />
                                              <Spacer size={{value: 25, unit: "px"}} />
                                          </>
                                          :
                                          <>
                                              <Image fit={"contain"} width={{value: 100, unit: "%"}} height={{value: 220, unit: "px"}} src={theme.placeholders.product} alt={data.options.name} />
                                              <Spacer size={{value: 25, unit: "px"}} />
                                          </>
                                      }
                                      {(data.servicesData.wineSchedaProdotto.canDownload || data.servicesData.grappaSchedaProdotto.canDownload) && (
                                          <>
                                              <Button
                                                  variant={"default-o"}
                                                  display={"inline-block"}
                                                  onClick={pdfSheetHandler}
                                              >
                                                  {translations["UI"]["Technical sheet"]}
                                              </Button>
                                          </>
                                      )}
                                  </Chunk>
                                  <Spacer size={{value: 25, unit: "px"}} />

                                  {
                                      (data.options.agingYears) ||
                                      (data.options.alcoholicGradation) ||
                                      (data.options.flavourAndTaste && data.options.flavourAndTaste.length > 0) ||
                                      (data.options.category) ||
                                      (data.options.classification) ||
                                      (data.options.specializations && data.options.specializations.length > 0) ||
                                      (data.options.priceRange) ||
                                      (data.options.olfactoryGustativeProfile) ||


                                      (data.wineTrees && data.wineTrees.length > 0) ?
                                          <>
                                              <Chunk title={translations["sheet-product"]["Presentation"]}>
                                                  { data.options.grapeVarieties && data.options.grapeVarieties.length > 0 ?
                                                      <InfoRow
                                                          label={translations["sheet-product"]["Grape varieties"]}
                                                          value={data.options.grapeVarieties.map((item) => item.percentage+"%"+ (item.name ? " " + item.name[lang] : "")).join(", ")}
                                                      />
                                                      : ""}
                                                  { data.options.agingYears ?
                                                      <InfoRow
                                                          label={translations["sheet-product"]["Ageing years"]}
                                                          value={data.options.agingYears}
                                                      />
                                                      : ""}
                                                  { data.options.alcoholicGradation ?
                                                      <InfoRow
                                                          label={translations["sheet-product"]["Alcoholic gradation"]}
                                                          value={data.options.alcoholicGradation}
                                                      />
                                                      : ""}
                                                  { data.options.flavourAndTaste && data.options.flavourAndTaste.length > 0 ?
                                                      <InfoRow
                                                          label={translations["sheet-product"]["Flavour and taste"]}
                                                          value={data.options.flavourAndTaste.map(item => item[lang]).join(", ")}
                                                      />
                                                      : ""}
                                                  {data.options.wineTypology && data.options.wineTypology.classification ?
                                                      <InfoRow
                                                          label={translations["sheet-product"]["Category"]}
                                                          /*value={data.category.join(", ") + (data.OtherType ? ", " + data.OtherType : null)}*/
                                                          value={data.options.wineTypology? (data.options.wineTypology.classification ? data.options.wineTypology.classification[lang] : "") + (data.options.wineTypology.category ? ", " + data.options.wineTypology.category[lang] : "") + (data.options.wineTypology.subCategory ? ", " + data.options.wineTypology.subCategory[lang] : "") : ""}
                                                      />
                                                      : ""}
                                                  {data.options.classification ?
                                                      <InfoRow
                                                          label={translations["sheet-product"]["Category"]}
                                                          value={data.options.classification[lang] + (data.options.marcType ? ", " + data.options.marcType[lang] : "") + (data.options.marcSubType ? ", " + data.options.marcSubType[lang] : "")}
                                                      />
                                                      : ""}
                                                  {/*data.category ?
                      <InfoRow
                        label={translations["sheet-product"]["Category"]}
                        value={data.category.join(", ") + (data.OtherType ? ", " + data.OtherType : null)}
                      />
                    : ""*/}
                                                  {data.options.specializations && data.options.specializations.length > 0 ?
                                                      <InfoRow
                                                          label={translations["sheet-product"]["Specializations"]}
                                                          value={data.options.specializations.map(item => item[lang]).join(", ")}
                                                      />
                                                      : ""}
                                                  {data.options.priceRange ?
                                                      <InfoRow
                                                          label={translations["sheet-product"]["Price range"]}
                                                          value={data.options.priceRange[lang]}
                                                      />
                                                      : ""}
                                                  { data.options.olfactoryGustativeProfile ?
                                                      <InfoRow
                                                          label={translations["sheet-product"]["Taste profile"]}
                                                          value={data.options.olfactoryGustativeProfile[lang]}
                                                      />
                                                      : ""}
                                              </Chunk>
                                              <Spacer size={{value: 25, unit: "px"}} />
                                          </>
                                          : "" }

                                  {
                                      (data.options.productionArea && (data.options.productionArea.countryId || data.options.productionArea.regionId)) ||
                                      (data.options.productionArea && (data.options.productionArea.country || data.options.productionArea.province)) ||
                                      (data.options.bottlesProduced)  ?
                                          <>
                                              <Chunk title={translations["sheet-product"]["Production"]}>
                                                  {data.options.productionArea && (data.options.productionArea.countryId || data.options.productionArea.regionId) ?
                                                      <InfoRow
                                                          label={translations["sheet-company"]["Production area"]}
                                                          value={data.options.productionArea.countryId ? data.options.productionArea.countryId[lang] + (data.options.productionArea.regionId ? ": "+data.options.productionArea.regionId[lang] : "") : ""}
                                                      />
                                                      : ""}
                                                  {data.options.productionArea && (data.options.productionArea.country || data.options.productionArea.province) ?
                                                      <InfoRow
                                                          label={translations["sheet-company"]["Production area"]}
                                                          value={data.options.productionArea.country ? data.options.productionArea.country[lang] + (data.options.productionArea.province ? ": "+data.options.productionArea.province[lang] : "") : ""}
                                                      />
                                                      : ""}
                                                  {data.options.bottlesProduced ?
                                                      <InfoRow
                                                          label={translations["sheet-product"]["Number of bottles produced"]}
                                                          value={data.options.bottlesProduced}
                                                      />
                                                      : ""}
                                              </Chunk>
                                              <Spacer size={{value: 25, unit: "px"}} />
                                          </>
                                          : "" }



                                  { data.options.awards && data.options.awards.length > 0 ?
                                      <>
                                          <Chunk title={translations["sheet-product"]["Awards"]}>
                                              {data.options.awards.map((val, index) =>
                                                  <InfoRow
                                                      key={"award-"+index}
                                                      label={val.award[lang]}
                                                      value={<div style={{"textAlign": "right"}}>{val.year && val.year !== 0 ? val.year : ""} {val.score && val.score !== 0 ? " (" + val.score + " pts)" : ""}</div>}
                                                  />
                                              )}
                                          </Chunk>
                                          <Spacer size={{value: 25, unit: "px"}} />
                                      </>
                                      : "" }



                                  { data.options.packaging && data.options.packaging.length > 0 ?
                                      <>
                                          <Chunk title={translations["sheet-product"]["Packaging"]}>
                                              <PackagingHeader layout={layout}>
                                                  <PackagingHeaderCell layout={layout} first={true}>{translations["sheet-product"]["Pack"]}</PackagingHeaderCell>
                                                  <PackagingHeaderCell layout={layout}>{translations["sheet-product"]["Weight"]}</PackagingHeaderCell>
                                                  <PackagingHeaderCell layout={layout}>{translations["sheet-product"]["Volume"]}</PackagingHeaderCell>
                                                  <PackagingHeaderCell layout={layout} last={true}>{translations["sheet-product"]["Bottle mouth"]}</PackagingHeaderCell>
                                              </PackagingHeader>
                                              {data.options.packaging.map((val, index) =>
                                                  <PackagingValue key={"packaging-"+index} layout={layout}>
                                                      <PackagingValueCell layout={layout} first={true}>{val.classification ? val.classification[lang] : null}</PackagingValueCell>
                                                      <PackagingValueCell layout={layout}>{val.weight ? val.weight[lang] : null}</PackagingValueCell>
                                                      <PackagingValueCell layout={layout}>{val.volume ? val.volume[lang] : null}</PackagingValueCell>
                                                      <PackagingValueCell layout={layout} last={true}>{val.bottleMouths && val.bottleMouths.length ? val.bottleMouths.map(val => val[lang]).join(", ") : null}</PackagingValueCell>
                                                  </PackagingValue>
                                              )}
                                          </Chunk>
                                          <Spacer size={{value: 25, unit: "px"}} />
                                      </>
                                      : "" }
                              </MacroCol>
                              <MacroCol size="34" layout={layout}>

                                  {data.options.exhibitor ?
                                      <>
                                          <Chunk open={true} collapsible={false}>
                                              {data.options.exhibitor.logo ?
                                                  <center>
                                                      <Image src={data.options.exhibitor.logo.src} width={{"value": 80, "unit": "%"}} height={{"value": 120, "unit": "px"}} fit={"contain"} alt="logo"/>
                                                  </center>
                                                  :
                                                  <CompanyName>{data.options.exhibitor.name}</CompanyName>
                                              }
                                              <Spacer size={{value: 20, unit: "px"}}/>
                                              <center>
                                                  <Link to={"/"+lang+"/companies/"+data.scope.exhibitorId+"/"+slugify(data.options.exhibitor.name)}>
                                                      <Button display="inline-block" variant={"default-o"}>{translations["sheet-product"]["_more-company-info"]}</Button>
                                                  </Link>
                                              </center>
                                          </Chunk>

                                          <Spacer size={{value: 14, unit: "px"}}/>
                                      </>
                                      : null}

                                  {false & data.halls && data.halls.length > 0 && config.enable_halls ?
                                      <>
                                          <FindUs>
                                              <HallImageCnt>
                                                  <Image src={config.ozwol.cdn + "/halls/void.svg"} alt="halls map"/>
                                                  {data.hallsImages.map((hall, index) =>
                                                      <HallImage key={"hallimage-"+index}>
                                                          <Image src={hall} alt="halls map"/>
                                                      </HallImage>
                                                  )}

                                              </HallImageCnt>
                                              {data.hallsString.map((hall, index) =>
                                                  <Position key={`hall-${hall}`}>{hall}</Position>
                                              )}
                                          </FindUs>

                                          <Spacer size={{value: 14, unit: "px"}}/>
                                      </>
                                      : null }

                                  {config.areas["products"].enable && data.companyProducts && data.companyProducts.length > 0 ?
                                      <>
                                          <Chunk title={translations["sheet-product"]["Other company products"]} open={true} collapsible={false}>
                                              <ReadMore
                                                  visibleContent={data.companyProducts.slice(0,2).map((item, index) =>{
                                                      let subareaSlug = null;
                                                      if(data){
                                                          let subareas = config.areas["products"].subareas;
                                                          Object.keys(subareas).forEach(function(index){
                                                              if(subareas[index].key === item.type){
                                                                  subareaSlug = index;
                                                              }
                                                          });
                                                      }
                                                      return (
                                                          <Result
                                                              key={index}
                                                              slug={item.slug}
                                                              area="products"
                                                              item={item}
                                                              onClick={() => null}
                                                              url={"/"+lang+"/products/"+subareaSlug+"/"+item.id}
                                                          />
                                                      )
                                                  })}
                                                  hiddenContent={data.companyProducts.slice(2).length ? data.companyProducts.slice(2).map((item, index) =>{
                                                      let subareaSlug = null;
                                                      if(data){
                                                          let subareas = config.areas["products"].subareas;
                                                          Object.keys(subareas).forEach(function(index){
                                                              if(subareas[index].key === item.type){
                                                                  subareaSlug = index;
                                                              }
                                                          });
                                                      }
                                                      return (
                                                          <Result
                                                              key={index}
                                                              slug={item.slug}
                                                              area="products"
                                                              item={item}
                                                              onClick={() => null}
                                                              url={"/"+lang+"/products/"+subareaSlug+"/"+item.id}
                                                          />
                                                      )
                                                  }) : null}
                                                  buttonMore={<Button size={"tiny"} variant={"default-o"} display={"inline-block"}>{translations["UI"]["Show more"]}</Button>}
                                                  buttonLess={<Button size={"tiny"} variant={"default-o"} display={"inline-block"}>{translations["UI"]["Show less"]}</Button>}
                                              />

                                              {config.areas["events"].enable && data.productEvents && data.productEvents.length > 0 ?
                                                  <>
                                                      <Chunk title={translations["sheet-company"]["Events"]} collapsible={false}>
                                                          <ReadMore
                                                              visibleContent={data.productEvents.slice(0,2).map((item, index) =>(
                                                                  <Result
                                                                      key={index}
                                                                      slug={item.slug}
                                                                      area="events"
                                                                      item={item}
                                                                      onClick={() => null}
                                                                      url={"/"+lang+"/events/"+item.type+"/"+item.id}
                                                                  />
                                                              ))}
                                                              hiddenContent={data.productEvents.slice(2).length ? data.productEvents.slice(2).map((item, index) =>(
                                                                  <Result
                                                                      key={index}
                                                                      slug={item.slug}
                                                                      area="events"
                                                                      item={item}
                                                                      onClick={() => null}
                                                                      url={"/"+lang+"/events/"+item.type+"/"+item.id}
                                                                  />
                                                              )) : null}
                                                              buttonMore={<Button size={"tiny"} variant={"default-o"} display={"inline-block"}>{translations["UI"]["Show more"]}</Button>}
                                                              buttonLess={<Button size={"tiny"} variant={"default-o"} display={"inline-block"}>{translations["UI"]["Show less"]}</Button>}
                                                          />
                                                      </Chunk>

                                                      <Spacer size={{value: 14, unit: "px"}}/>
                                                  </>
                                                  : null}

                                          </Chunk>

                                          <Spacer size={{value: 14, unit: "px"}}/>
                                      </>
                                      : null }

                              </MacroCol>
                          </MacroRow>


                          <Spacer size={{value: 60, unit: "px"}} />

                          {/*
          <Company>{data.options.exhibitor.name}</Company>
          <Spacer size={{value: 15, unit: "px"}} />
          <Brand>{data.brand}</Brand>
          <Spacer size={{value: 10, unit: "px"}} />
          <Designation>{data.designation[data.designation.length - 1]}</Designation>
          <Spacer size={{value: 60, unit: "px"}} />
          */}




                      </Container>
                  </MainContainer>
              </Wrapper>



              <Modal show={showModal} onClose={e => toggleModal(false)}>
                  {showModal === "contact" ?
                      <>
                          <ContactsModal>
                              <ContactsModalHeader>
                                  {/*<img src={data.companyData.logo} />*/}
                                  <p>{translations['sheet-company'][`_intro-contatto-diretto-company`].replace('%s', data.options.exhibitor.name)}</p>
                              </ContactsModalHeader>
                              <ContactForm lang={lang} config={config} translations={translations} company={data.options.exhibitor.name} reset={!showModal} toEmail={data.companyData.emails[0]} toName={data.options.exhibitor.name} type={"company"} />
                          </ContactsModal>
                      </>
                      : null }
                  {showModal === "technical" ?
                      <>
                          <ContactsModal>
                              <ContactsModalHeader>
                                  <ModalTitle>{translations["UI"]["Technical sheet download"]}</ModalTitle>
                              </ContactsModalHeader>
                              {data.type === "wine" && data.servicesData.wineSchedaProdotto.canDownload ?
                                  data.servicesData.wineSchedaProdotto.enabledLanguages.sort((a, b) => config.languages.indexOf(a) - config.languages.indexOf(b)).map((item, index) =>(
                                      <InfoRow
                                          key={index}
                                          label={(() => {
                                              switch (item) {
                                                  case "it":
                                                      return "Italiano";
                                                  case "en":
                                                      return "English";
                                                  case "zh":
                                                      return "中文";
                                                  case "ja":
                                                      return "日本語";
                                                  case "ru":
                                                      return "русский";
                                                  case "de":
                                                      return "Deutsch";
                                                  case "fr":
                                                      return "Français";
                                                  case "es":
                                                      return "Español";
                                                  case "pt":
                                                      return "Português";
                                                  case "ko":
                                                      return "한국의";
                                                  default:
                                                      return "Uncoded Language";
                                              }
                                          })()}
                                          value={<a rel="noopener noreferrer" href={(config.chatConfig.hasOwnProperty(window.location.hostname) ? config.chatConfig[window.location.hostname].apiUrl : config.chatConfig.default.apiUrl) + "technicalSheet?id="+data.id+"&language="+item} target="_blank"><Button
                                              display={"inline-block"}
                                          >
                                              {translations["UI"]["Download"]}
                                          </Button></a>}
                                      />
                                  ))
                                  : null }
                              {false && data.type === "grappa" && data.servicesData.grappaSchedaProdotto.canDownload ?
                                  data.servicesData.grappaSchedaProdotto.enabledLanguages.sort((a, b) => config.languages.indexOf(a) - config.languages.indexOf(b)).map((item, index) =>(
                                      <InfoRow
                                          key={index}
                                          label={(() => {
                                              switch (item) {
                                                  case "it":
                                                      return "Italiano";
                                                  case "en":
                                                      return "English";
                                                  case "zh":
                                                      return "中文";
                                                  case "ja":
                                                      return "日本語";
                                                  case "ru":
                                                      return "русский";
                                                  case "de":
                                                      return "Deutsch";
                                                  case "fr":
                                                      return "Français";
                                                  case "es":
                                                      return "Español";
                                                  case "pt":
                                                      return "Português";
                                                  case "ko":
                                                      return "한국의";
                                                  default:
                                                      return "Uncoded Language";
                                              }
                                          })()}
                                          value={<a rel="noopener noreferrer" href={(config.chatConfig.hasOwnProperty(window.location.hostname) ? config.chatConfig[window.location.hostname].apiUrl : config.chatConfig.default.apiUrl) + "technicalSheet?id="+data.id+"&language="+item} target="_blank"><Button
                                              display={"inline-block"}
                                          >
                                              {translations["UI"]["Download"]}
                                          </Button></a>}
                                      />
                                  ))
                                  : null }
                          </ContactsModal>
                      </>
                      : null }
              </Modal>

          </>
          ) : <></>
      </>
  ) : <></>;
}

export default compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps)
)(VirCommonSheet);
