import React, { useEffect, useState } from 'react';
import { Link, useParams, withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getLayout } from "./../../../store/settings/selectors";
import { getTranslations, getLocale } from "./../../../store/localization/selectors";
// import { getUser } from "./../../../store/auth/selectors";
import {useTheme} from "./../../../themes";

import {LAYOUT_XS, LAYOUT_SM, LAYOUT_MD} from './../../../settings';

import NewsService from './../../../services/NewsService';

import Logo from './../../../components/App/Logo';
import Container from './../../../components/App/Container';
import Spacer from './../../../components/App/Spacer';
import Post from './../../../components/App/Post';

// import FindUs from './../../../components/Common/FindUs';
import Sidebar from './Sidebar';
import MenuItem from "./../../../components/App/MenuItem";
import Icon from "./../../../components/App/Icon";
import FeaturedList from "./FeaturedList";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;

  height: calc(100vh - 50px);
  height: calc(calc(var(--vh, 1vh) * 100) - 50px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  margin-top: 3px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* min-height: 100%; */
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
`;
const MacroRow = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  margin-left: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
`;
const MacroCol = styled.div`
  padding: 0px ${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;
`;

const Notification = styled.div`
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  color: #A3A3A3;
  margin-top:20px;
`;

const Item = styled.div`
  border-radius: 12px;
  box-shadow: 0px 3px 5px #5858580F;
  overflow: hidden;
  margin-bottom: 10px;
  display: inline-block;

`;


function WinePeople({history, featured}){
  const theme = useTheme();

  const layout = useSelector(getLayout);
  const translations = useSelector(getTranslations);
  const lang = useSelector(getLocale);
  // const config = useSelector(getConfig);
  // const user = useSelector(getUser);
  let { id } = useParams();
  const { featuredList, products } = featured;

  const [list, setList] = useState(null);
  const [single, setSingle] = useState(null);
  const [listDx, setListDx] = useState(null);

  useEffect(() => {
    const unlisten = history.listen(() => {
      document.getElementById("maincnt").scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, [history]);

  if(id){
    if(!single){
      NewsService.getList("Wine people").then(ret  => {
        setSingle(ret.filter(item => item["slug_"+lang] === id)[0]);
        setList(null);
      }).catch(err => console.log('There was an error:' + err));
    }
  }else{
    if(!list){
      NewsService.getList("Wine people").then(ret  => {
        setSingle(null);
        setList(ret);
      }).catch(err => console.log('There was an error:' + err));
    }
  }

  if(!listDx){
    NewsService.getList("Frame DX").then(ret  => {
      setListDx(ret);
      // console.log(ret);
    }).catch(err => console.log('There was an error:' + err));
  }



  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  return (
    <Wrapper>
      { layout !== LAYOUT_XS && layout !== LAYOUT_SM ?
        <Spacer size={{value: 34, unit: "px"}} />
      : <Sidebar selected={"winepeople"} /> }
      <MainContainer id="maincnt">
        <Container>
          <MacroRow layout={layout}>
            <MacroCol size="66" layout={layout}>
              <center>
                { layout === LAYOUT_XS || layout === LAYOUT_SM ?
                  <Spacer size={{value: 34, unit: "px"}} />
                : null }
                <Logo height={{value: 56, unit: "px"}} variant="plus" />
                <Spacer size={{value: 27, unit: "px"}} />
              </center>
              {list ? list.map((item, i) =>
                <React.Fragment key={i}>
                  {item["titolo_"+(lang === "it" ? lang : "en")] || item["abstract_"+(lang === "it" ? lang : "en")] ?
                    <>
                      <Post
                        tags={["Wine People"]}
                        title={item["titolo_"+(lang === "it" ? lang : "en")]}
                        abstract={ReactHtmlParser(item["abstract_"+(lang === "it" ? lang : "en")])}
                        content={null}
                        ctaText={translations["UI"]["Show more"]}
                        ctaLinkInternal={"/"+lang+"/winepeople/"+item["slug_"+lang]}
                        timestamp={item.data_pubblicazione}
                        image={item.immagine}
                      />
                      <Spacer size={{value: 34, unit: "px"}} />
                      {featuredList && featuredList[i] ?
                        <FeaturedList block={featuredList[i]} products={products} />
                        : null }
                    </>
                  : null }
                  <Spacer size={{value: 34, unit: "px"}} />
                </React.Fragment>
              ) : null }

              {single ?
                <>
                  <Link to={"/"+lang+"/winepeople/"}>
                    <Item>
                      <MenuItem
                        leftIcon={<Icon color={"#585858"} name={theme.icons.set.menuitemPrev} />}
                        variant={theme.homepage.menuitemVariant}
                        size={theme.homepage.menuitemSize}
                      >{translations["UI"]["Back"]}</MenuItem>
                    </Item>
                  </Link>
                  <Post
                    tags={["Wine People"]}
                    title={single["titolo_"+(lang === "it" ? lang : "en")]}
                    abstract={ReactHtmlParser(single["abstract_"+(lang === "it" ? lang : "en")]) + single["testo_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(single["testo_"+(lang === "it" ? lang : "en")]) : null}
                    content={null}
                    ctaText={single["cta_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(single["cta_"+(lang === "it" ? lang : "en")]) : null}
                    ctaLink={single["link_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(single["link_"+(lang === "it" ? lang : "en")]) : null}
                    timestamp={single.data_pubblicazione}
                    image={single.immagine}
                  />
                  <Spacer size={{value: 34, unit: "px"}} />
                </>
              : null }

            </MacroCol>
            <MacroCol size="34" layout={layout}>
              <Spacer size={{value: 95, unit: "px"}} />
              {/*<FindUs />
              <Spacer size={{value: 34, unit: "px"}} />*/}
              {listDx ? listDx.map((item, i) =>
                <React.Fragment key={i}>
                  <Post
                    isHighlighted={true}
                    title={item["titolo_"+(lang === "it" ? lang : "en")]}
                    abstract={ReactHtmlParser(item["abstract_"+(lang === "it" ? lang : "en")])}
                    content={item["testo_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(item["testo_"+(lang === "it" ? lang : "en")]) : null}
                    ctaText={item["cta_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(item["cta_"+(lang === "it" ? lang : "en")]) : null}
                    ctaLink={item["link_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(item["link_"+(lang === "it" ? lang : "en")]) : null}
                    image={item.immagine}
                  />
                  <Spacer size={{value: 34, unit: "px"}} />
                </React.Fragment>
              ) : null }
            </MacroCol>
          </MacroRow>
          <Notification>
            {translations["UI"]["_homepage-language-info"]}
            <Spacer size={{value: 34, unit: "px"}} />
          </Notification>
        </Container>
      </MainContainer>
    </Wrapper>

  );

}
export default withRouter(WinePeople);
