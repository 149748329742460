import React from 'react';
import Logo from "../../../components/App/Logo";
import {useTheme} from "../../../themes";

export default function HeaderLogo () {
  const theme = useTheme();
  const logoTheme = theme.topbar.mobile.logo;

  return (
    <Logo {...logoTheme} />
  );
}
