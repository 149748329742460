import { createTheming, type ThemingType } from '@callstack/react-theme-provider';

import {VIR} from "./tenants/VIR/theme";
import {MDM} from "./tenants/MDM/theme";
import {AGR} from "./tenants/AGR/theme";
import {FIC} from "./tenants/FIC/theme";
import {SAM} from "./tenants/SAM/theme";
import {ASF} from "./tenants/ASF/theme";

import './tenants/VIR/fonts/fonts.css';
import './tenants/MDM/fonts/fonts.css';
import './tenants/AGR/fonts/fonts.css';
import './tenants/FIC/fonts/fonts.css';
import './tenants/ASF/fonts/fonts.css';

export type Theme = {
  primaryColor: string,
  homeBackground: string,
  screenBackground: string,
  interfaceBackground: string
};

export const themes: { [key: string]: Theme } = {
  VIR,
  MDM,
  AGR,
  FIC,
  SAM,
  ASF
};

const { ThemeProvider, useTheme, withTheme }: ThemingType<Theme> = createTheming(
  themes.default
);

export { ThemeProvider, useTheme, withTheme };
