import React from 'react';
// import { Link, useParams } from 'react-router-dom';
// import ReactHtmlParser from 'react-html-parser';
import styled from "styled-components";
import { useDispatch, useSelector} from "react-redux";
import { getLayout } from "./../../../store/settings/selectors";
import { getTranslations } from "./../../../store/localization/selectors";
import {toggleFilters} from "./../../../store/settings/actions";

import {LAYOUT_XS, LAYOUT_SM} from './../../../settings';

// import Logo from './../../../components/App/Logo';
// import Container from './../../../components/App/Container';
// import Spacer from './../../../components/App/Spacer';
import Image from './../../../components/App/Image';
import Button from "./../../../components/App/Button";

import heroLogo from './logo.svg';
import heroBg from './testata.jpg';

import organizedVrf from './logo-vrf.png';
import organizedSiteb from './logo-siteb.jpg';


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;

  height: calc(100vh - 60px);
  height: calc(calc(var(--vh, 1vh) * 100) - 60px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  margin-top: 3px;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* min-height: 100%; */
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  text-align: center;
`;

const Top = styled.div`
  padding: 20px;
  background: #22343E;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #ffffff;
  font-size: ${props => props.mobile ? 18 : 28}px;
  line-height: ${props => props.mobile ? 20 : 32}px;
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
`;



// const LogoWrapper = styled.div`
//   max-width: 90%;
// `;

const Hero = styled.div`
  width: 100%;
  position: relative;
  padding: 10px 30px 160px;
  background-image: url('${props => props.background}');
  background-color: #000000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const HeroText = styled.div`
  font-size: ${props => props.mobile ? 20 : 38}px;
  line-height: ${props => props.mobile ? 30 : 52}px;
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
  max-width: 300px;

  text-align: center;
  text-shadow: #000000 0px 0 10px;
  overflo: hidden;
`;
const HeroTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  text-shadow: #000000 0px 0 10px;
  line-height: 16px;
  overflo: hidden;

  & b{
    font-size: 100px;
    font-weight: 900;
    color: #F5BA44;
    text-shadow: #00000099 0px 0 5px;
    line-height: 76px;left: -7px;
    position: relative;
  }
  
`;

const CrossArea = styled.div`
  padding: 30px 30px 30px;
  text-align: center;
`;
const CrossText = styled.div`
  padding-bottom: 15px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
`;


const OrganizedBy = styled.div`
  padding-bottom: 15px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
`;
const OrganizedByLogos = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 45px;
  & > * {
    padding: 0px 10px;
  }
`;


export default function Home(){

  const dispatch = useDispatch();
  dispatch(toggleFilters(false));

  const layout = useSelector(getLayout);
  const translations = useSelector(getTranslations);
  // const lang = useSelector(getLocale);
  // const config = useSelector(getConfig);


  return (
    <Wrapper>
      <MainContainer>
        <Top mobile={layout === LAYOUT_XS || layout === LAYOUT_SM}>
          <Image src={heroLogo} width={{"value": 300, "unit": "px"}}/>
          <br/><br/>
          {translations["homepage"]["_home-text"]}
        </Top>
        <Hero background={heroBg} id="hero">
          <HeroText mobile={layout === LAYOUT_XS || layout === LAYOUT_SM}>
            <HeroTitle dangerouslySetInnerHTML={{__html: translations["homepage"]["_home-title"]}} />
          </HeroText>
        </Hero>
        <CrossArea>
          <CrossText dangerouslySetInnerHTML={{__html: translations["homepage"]["_home-cross-text"]}} />
          <center>
            <a rel="noopener noreferrer" target="_blank" href={"https://catalogo.samoter.it/"}><Button>{translations["homepage"]["_home-cta"]}</Button></a>
            <br/><br/><br/>
            <OrganizedBy>
              {translations["homepage"]["_home-organized-by"]}            
            </OrganizedBy>
            <OrganizedByLogos>
              <Image src={organizedVrf} width={{"value": 150, "unit": "px"}}/>
              <Image src={organizedSiteb} width={{"value": 140, "unit": "px"}}/>
            </OrganizedByLogos>
          </center>
        </CrossArea>
      </MainContainer>
    </Wrapper>

  );

}
